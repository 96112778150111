import axios from 'axios'
import store from '../store'
import router from '../router'
import { ElMessage, ElLoading } from 'element-plus'

let baseURL = `${process.env.VUE_APP_BASE_ROUTE}/v1`

// 建立 axios 實例
const myAxios = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
})

const err = (error) => {
    if (error.response) {
        let data = error.response.data
        const token = store.getters.getToken
        const serverRole = store.getters.getServerRole
        if (error.response.status == 401) {
            ElMessage.error('ログイン画面より再度ログインしてください。')
            setTimeout(() => {
                store.commit('logout')
                if (serverRole === 'admin') {
                    router.push({ name: 'AdminSystemLogin' })
                } else {
                    router.push({ name: 'MemberSystemLogin' })
                }
            }, 2000)
        } else if (error.response.status == 422 && token) {
            ElMessage.error('ログイン画面より再度ログインしてください。')
            setTimeout(() => {
                store.commit('logout')
                if (serverRole === 'admin') {
                    router.push({ name: 'AdminSystemLogin' })
                } else {
                    router.push({ name: 'MemberSystemLogin' })
                }
            }, 2000)
        } else {
            console.log(`Error:${error.response.status} ${data.message}`)
            ElMessage.error(data.message)
        }
        return Promise.reject(error)
    }
}

// request 攔截器
myAxios.interceptors.request.use(
    (config) => {
        const token = store.getters.getToken
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

// response 攔截器
myAxios.interceptors.response.use((response) => {
    return response.data
}, err)

/*  GET  */

export function get(url, params) {
    return new Promise((resolve, reject) => {
        let loadingInstance = ElLoading.service()
        myAxios
            .get(url, { params: params })
            .then((response) => {
                loadingInstance.close()
                resolve(response)
            })
            .catch((error) => {
                loadingInstance.close()
                reject(error)
            })
    })
}

/*  POST  */

export function post(url, data, params) {
    return new Promise((resolve, reject) => {
        let loadingInstance = ElLoading.service()
        myAxios
            .post(url, data, { params: params })
            .then((response) => {
                loadingInstance.close()
                resolve(response)
            })
            .catch((error) => {
                loadingInstance.close()
                reject(error)
            })
    })
}

/*  PUT  */

export function put(url, data) {
    return new Promise((resolve, reject) => {
        let loadingInstance = ElLoading.service()
        myAxios
            .put(url, data)
            .then((response) => {
                loadingInstance.close()
                resolve(response)
            })
            .catch((error) => {
                loadingInstance.close()
                reject(error)
            })
    })
}

/*  DELETE  */

export function del(url, data) {
    return new Promise((resolve, reject) => {
        let loadingInstance = ElLoading.service()
        myAxios
            .delete(url, { data })
            .then((response) => {
                loadingInstance.close()
                resolve(response)
            })
            .catch((error) => {
                loadingInstance.close()
                reject(error)
            })
    })
}

export default {
    get,
    post,
    put,
    del,
}
